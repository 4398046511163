import header from '@/public/header/header.webp';
import resources from '@/public/header/resources.webp';
import { NavItemType } from '@/types/navbar';
import { IRoutePath } from '@/types/router';

export const navItem = [
  {
    href: IRoutePath.HOME,
    title: 'Home',
    type: NavItemType.LINK,
    sub_menu: [],
  },

  {
    href: IRoutePath.FEATURES,
    title: 'Features',
    type: NavItemType.BUTTON,
    header_type: 'nested-list',
    sub_menu: [
      {
        title: 'Get Paid & Expenses',
        type: NavItemType.BUTTON,
        items: [
          {
            title: 'Commissions Management',
            href: IRoutePath.FEATURES_INVOICING,
            type: NavItemType.LINK,
          },
          {
            title: 'QuickBooks Integration',
            href: IRoutePath.FEATURES_INVOICING,
            type: NavItemType.LINK,
          },
          {
            title: 'Consumer Financing',
            href: IRoutePath.FEATURES_CONSUMER_FINANCING,
            type: NavItemType.LINK,
          },
          {
            title: 'Expenses Tracking',
            href: IRoutePath.FEATURES_INVOICING,
            type: NavItemType.LINK,
          },
          {
            title: 'Contractor Payroll',
            href: IRoutePath.FEATURES_INVOICING,
            type: NavItemType.LINK,
          },
          {
            title: 'Card Reader',
            href: IRoutePath.FEATURES_INVOICING,
            type: NavItemType.LINK,
          },
          {
            title: 'Payment',
            href: IRoutePath.FEATURES_PAYMENT_MANAGEMENT,
            type: NavItemType.LINK,
          },
        ],
      },
      {
        title: 'Job Management',
        type: NavItemType.BUTTON,
        items: [
          {
            title: 'Work Order Management',
            href: IRoutePath.FEATURES_SCHEDULIG,
            type: NavItemType.LINK,
          },
          {
            title: 'Field Service CRM',
            href: IRoutePath.FEATURES_SCHEDULIG,
            type: NavItemType.LINK,
          },
          {
            title: 'Job Management',
            href: IRoutePath.FEATURES_SCHEDULIG,
            type: NavItemType.LINK,
          },
          {
            title: 'Employee Time Tracking',
            href: IRoutePath.FEATURES_TIME_TRACKING,
            type: NavItemType.LINK,
          },
          {
            title: 'Job Costing',
            href: IRoutePath.FEATURES_SCHEDULIG,
            type: NavItemType.LINK,
          },
          {
            title: 'Recurring Jobs',
            href: IRoutePath.FEATURES_RECURRING_JOBS,
            type: NavItemType.LINK,
          },
          {
            title: 'Supplies order',
            href: IRoutePath.FEATURES_SUPPLIES_ORDER,
            type: NavItemType.LINK,
          },
          {
            title: 'Job Tracking',
            href: IRoutePath.FEATURES_JOB_TRACKING,
            type: NavItemType.LINK,
          },
          {
            title: 'Equipment tracking',
            href: IRoutePath.FEATURES_SCHEDULIG,
            type: NavItemType.LINK,
          },
        ],
      },
      {
        title: 'Complete Job & Invoice',
        type: NavItemType.BUTTON,
        items: [
          {
            title: 'Sales Proposal Tool',
            href: IRoutePath.FEATURES_SALES_PROPOSAL,
            type: NavItemType.LINK,
          },
          {
            title: 'Invoicing',
            href: IRoutePath.FEATURES_ESTIMATES,
            type: NavItemType.LINK,
          },
          {
            title: 'Estimates',
            href: IRoutePath.FEATURES_ESTIMATES,
            type: NavItemType.LINK,
          },
          {
            title: 'Quoting',
            href: IRoutePath.FEATURES_ESTIMATES,
            type: NavItemType.LINK,
          },
          {
            title: 'Mobile App',
            href: IRoutePath.FEATURES_MOBILE_APP,
            type: NavItemType.LINK,
          },
          {
            title: 'Review Management',
            href: IRoutePath.FEATURES_REVIEW_MANAGMENT,
            type: NavItemType.LINK,
          },
          {
            title: 'Price pages',
            href: IRoutePath.FEATURES_PRICE_PAGES,
            type: NavItemType.LINK,
          },
          {
            title: 'Accounting',
            href: IRoutePath.FEATURES_ACCOUNTING,
            type: NavItemType.LINK,
          },
        ],
      },
      {
        title: 'Scheduling & Dispatching',
        type: NavItemType.BUTTON,
        items: [
          {
            title: 'Employee GPS Tracking',
            href: IRoutePath.FEATURES_GPS_TRACKING,
            type: NavItemType.LINK,
          },
          {
            title: 'Scheduling Software',
            href: IRoutePath.FEATURES_SCHEDULIG,
            type: NavItemType.LINK,
          },
          {
            title: 'Route Optimization',
            href: IRoutePath.FEATURES_DISPATCHIG,
            type: NavItemType.LINK,
          },
          {
            title: 'Field Service App',
            href: IRoutePath.FEATURES_FIELD_SERVICE_MANAGMENT,
            type: NavItemType.LINK,
          },

          {
            title: 'Call Recording',
            href: IRoutePath.FEATURES_DISPATCHIG,
            type: NavItemType.LINK,
          },

          {
            title: 'Dispatching',
            href: IRoutePath.FEATURES_DISPATCHIG,
            type: NavItemType.LINK,
          },
        ],
      },

      {
        title: 'Business Solutions',
        type: NavItemType.BUTTON,
        items: [
          {
            title: 'Automated Direct Mail',
            href: IRoutePath.FEATURES_SCHEDULIG,
            type: NavItemType.LINK,
          },
          {
            title: 'Advanced Reporting',
            href: IRoutePath.FEATURES_ADVANCED_REPORTING,
            type: NavItemType.LINK,
          },
          {
            title: 'Home Services Marketing',
            href: IRoutePath.FEATURES_SCHEDULIG,
            type: NavItemType.LINK,
          },
          {
            title: 'Text Messaging',
            href: IRoutePath.FEATURES_SCHEDULIG,
            type: NavItemType.LINK,
          },
          {
            title: 'Voice Solutions',
            href: IRoutePath.FEATURES_SCHEDULIG,
            type: NavItemType.LINK,
          },
        ],
      },

      // {
      //   title: 'All Features',
      //   href: IRoutePath.FEATURES,
      //   type: NavItemType.LINK,
      //   items: [
      //     {
      //       title: 'Scheduling Software',
      //       href: IRoutePath.FEATURES_SCHEDULIG,
      //       type: NavItemType.LINK,
      //     },
      //     {
      //       title: 'Estimates',
      //       href: IRoutePath.FEATURES_ESTIMATES,
      //       type: NavItemType.LINK,
      //     },
      //     {
      //       title: 'Dispatching',
      //       href: IRoutePath.FEATURES_DISPATCHIG,
      //       type: NavItemType.LINK,
      //     },
      //     {
      //       title: 'Invoicing',
      //       href: IRoutePath.FEATURES_INVOICING,
      //       type: NavItemType.LINK,
      //     },
      //     {
      //       title: 'Sales Proposal Tool',
      //       href: IRoutePath.FEATURES_SALES,
      //       type: NavItemType.LINK,
      //     },
      //     // {
      //     //   title: 'Home Service Agreement',
      //     //   href: '#',
      //     //   type: NavItemType.LINK,
      //     // },
      //     // { title: 'Job Managment', href: '#', type: NavItemType.LINK },
      //     // { title: 'Quoting', href: '#', type: NavItemType.LINK },
      //     // { title: 'Route Tracking', href: '#', type: NavItemType.LINK },
      //     // { title: 'Employee GPS Tracking', href: '#', type: NavItemType.LINK },
      //   ],
      // },
    ],
  },

  {
    href: IRoutePath.INDUSTRIES,
    title: 'Industries',
    type: NavItemType.BUTTON,
    header_type: 'simple-list',
    sub_menu: [
      {
        title: 'HVAC',
        type: NavItemType.LINK,
        items: [],
        href: IRoutePath.INDUSTRIES_HVAC,
      },
      {
        title: 'Plumbing',
        type: NavItemType.LINK,
        items: [],
        href: IRoutePath.INDUSTRIES_PLUMBING,
      },
      {
        title: 'Construction',
        type: NavItemType.LINK,
        items: [],
        href: IRoutePath.INDUSTRIES_CONSTRUCTION,
      },
      {
        title: 'Appliance Repair',
        type: NavItemType.LINK,
        items: [],
        href: IRoutePath.INDUSTRIES_APPLIANCE,
      },
      {
        title: 'Electrician',
        type: NavItemType.LINK,
        items: [],
        href: IRoutePath.INDUSTRIES_ELECTRICIAN,
      },
      {
        title: 'Garage Door',
        type: NavItemType.LINK,
        items: [],
        href: IRoutePath.INDUSTRIES_GARAGE_DOOR,
      },
      {
        title: 'Handyman',
        type: NavItemType.LINK,
        items: [],
        href: IRoutePath.INDUSTRIES_HANDYMAN,
      },
      {
        title: 'Home Cleaning',
        type: NavItemType.LINK,
        items: [],
        href: IRoutePath.INDUSTRIES_HOME_CLEANING,
      },
      {
        title: 'Carpet Cleaning',
        type: NavItemType.LINK,
        items: [],
        href: IRoutePath.INDUSTRIES_CARPET_CLEANING,
      },

      {
        title: 'Painting',
        type: NavItemType.LINK,
        items: [],
        href: IRoutePath.INDUSTRIES_PAINTING,
      },
      {
        title: 'Window Cleaning',
        type: NavItemType.LINK,
        items: [],
        href: IRoutePath.INDUSTRIES_WINDOW_CLEANING,
      },
      {
        title: 'Locksmith',
        type: NavItemType.LINK,
        items: [],
        href: IRoutePath.INDUSTRIES_LOCKSMITH,
      },
      {
        title: 'Property Maintenance',
        type: NavItemType.LINK,
        items: [],
        href: IRoutePath.INDUSTRIES_PROPERTY_MAINTENANCE,
      },

      {
        title: 'Junk Removal',
        type: NavItemType.LINK,
        items: [],
        href: IRoutePath.INDUSTRIES,
      },
      {
        title: 'Fire Safety',
        type: NavItemType.LINK,
        items: [],
        href: IRoutePath.INDUSTRIES_FIRE_SAFETY,
      },
    ],
  },
  {
    href: IRoutePath.PRICING,
    title: 'Pricing',
    type: NavItemType.LINK,
    sub_menu: [],
  },
  {
    href: IRoutePath.RECOURCES,
    title: 'Resources',
    type: NavItemType.BUTTON,
    header_type: 'list-with-image',
    image: resources,
    sub_menu: [
      {
        title: 'Blog',
        type: NavItemType.LINK,
        items: [],
        href: IRoutePath.BLOG,
        image: header,
        description:
          'Stay informed and inspired with our engaging blog, featuring industry insights and expert tips',
      },

      {
        title: 'Tutorial',
        type: NavItemType.LINK,
        items: [],
        href: IRoutePath.TUTORIAL,
        image: header,
        description:
          'Step-by-step tutorials: Creating, editing, and scheduling on the site. Our tutorials will help you understand how to create projects, grasp the meanings of various statuses, and efficiently schedule meetings',
      },
    ],
  },
  {
    href: IRoutePath.COMPANY,
    title: 'Company',
    type: NavItemType.BUTTON,
    header_type: 'list-with-image',
    image: header,
    sub_menu: [
      {
        group: 'Company',
        title: 'Contact Us',
        type: NavItemType.LINK,
        items: [],
        href: IRoutePath.COMPANY_CONTACT_US,
        description:
          'Orcatec is your all-in-one home services management software designed to save you time, money, and boost productivity. It organizes all your tasks, synchronizes the work of your team, technicians, and clients.',
        image: header,
      },
      {
        title: 'About Us',
        type: NavItemType.LINK,
        items: [],
        href: IRoutePath.COMPANY_UBOUR_US,
        description:
          'Get in touch with our team anytime! We are here to answer any question may you have.',
        image: header,
      },
    ],
  },
];
